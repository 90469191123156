<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Product Stock Request</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="stockSuppliesInsert">
              <div class="card-body">
                <div class="form-group">
                  <label>User Request</label>
                  <input
                    type="text"
                    class="form-control"
                    readonly
                    v-model="form.request_by"
                  />
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <label>Supplier</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.supplier"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Receiver</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.receiver"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Stock Request</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(stock, index) in stocks" :key="index">
                      <td>{{ stock.name_product }}</td>
                      <td>{{ stock.stock }}/{{ stock.name_unit }}</td>
                      <td>Rp. {{ formatPrice(stock.price) }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="invoice-detail-item text-right">
                  <div class="invoice-detail-name">Total</div>
                  <h3 class="font-weight-bold invoice-detail-value-lg">
                    Rp. {{ formatPrice(amount) }}
                  </h3>
                </div>
                <div class="form-group">
                  <label>Status</label>
                  <select class="form-control" v-model="form.status">
                    <option value="">Select Status</option>
                    <option
                      :value="index"
                      v-for="(status, index) in statuses"
                      :key="index"
                    >
                      {{ status }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Note</label>
                  <textarea
                    v-model="form.note"
                    class="form-control"
                    style="height:100px"
                  ></textarea>
                </div>
                <div class="row">
                  <div class="col">
                    <StreamBarcodeReader
                      @decode="onDecode"
                      @loaded="onLoaded"
                    ></StreamBarcodeReader>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>User ID</label>
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        v-model="form.IDuser"
                      />
                      <small class="text-danger" v-if="errors.IDuser">{{
                        errors.IDuser[0]
                      }}</small>
                    </div>
                    <div class="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        v-model="form.NameUser"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../services/api";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  name: "FormPaymentType",
  components: { StreamBarcodeReader },
  data() {
    return {
      name: "Detail Product Stock Supplier",
      form: {
        note: "",
        status: "",
        supplier: "",
        receiver: "",
        request_by: "",
        IDuser: "",
        NameUser: "",
      },
      amount: [],
      statuses: [],
      stocks: [],
      isSubmitting: false,
      errors: {},
      edit: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    onDecode(result) {
      this.form.IDuser = result;
      let formData = new FormData();
      formData.set("aplication_user_id", this.form.IDuser);
      axios
        .post(this.$store.state.api + "get_member_card", formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((result) => {
          this.$noty.success("We Got Your Member Code!");
          this.form.NameUser = result.data.data.name;
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
    onLoaded() {
      console.log("TEST");
    },
    getData() {
      let id = this.$route.params.id;
      apiGet("stock_supplies/" + id)
        .then((result) => {
          this.form.note = result.data.data.note;
          this.form.status = result.data.data.status;
          this.form.supplier = result.data.data.supplies_name;
          this.form.receiver = result.data.data.receiver_name;
          this.form.request_by = result.data.data.created_by_name;
          this.form.IDuser = result.data.data.supplier_code;
          this.form.NameUser = result.data.data.supplier_account;
          this.stocks = result.data.data.stocks_in;
          this.amount = result.data.data.amount;
          this.edit = true;
        })
        .catch((err) => {
          console.log(err);
        });
      apiGet("stock_request/get_status").then((result) => {
        this.statuses = result.data;
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    stockSuppliesInsert() {
      this.isSubmitting = true;
      let id = this.$route.params.id;

      axios
        .post(
          this.$store.state.api + "stock_supplies/update/" + id,
          this.form,
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.$noty.success("Your Stock Supplies has been updated!");
          this.$router.push({ name: "ProductStockSupplies" });
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
