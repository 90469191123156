import ProductStockSupplies from './ProductStockSupplies.vue'
import ProductStockSuppliesCreate from './Create.vue'
import ProductStockSuppliesEdit from './Create.vue'
import ProductStockSuppliesShow from './Show.vue'

export default [
    {
        path: '/product_stock_supplies',
        name: 'ProductStockSupplies',
        component: ProductStockSupplies
    },
    {
        path: '/product_stock_supplies/create',
        name: 'ProductStockSuppliesCreate',
        component: ProductStockSuppliesCreate
    },
    {
        path: '/product_stock_supplies/edit/:id',
        name: 'ProductStockSuppliesEdit',
        component: ProductStockSuppliesEdit
    },
    {
        path: '/product_stock_supplies/show/:id',
        name: 'ProductStockSuppliesShow',
        component: ProductStockSuppliesShow
    },
]
