<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Product Stock Supplies</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="productStockSupplierInsert">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Supplier</label>
                          <model-list-select
                            :list="suppliers"
                            v-model="form.supplier_id"
                            option-value="id"
                            option-text="name"
                            placeholder="Select Supplier"
                          >
                          </model-list-select>
                          <small
                            class="text-danger"
                            v-if="errors.supplier_id"
                            >{{ errors.supplier_id[0] }}</small
                          >
                        </div>
                      </div>
                      <!-- <div class="col-md-6">
                        <div class="form-group">
                          <label>Receiver</label>
                          <model-list-select
                            :list="venues"
                            v-model="form.receiver_id"
                            option-value="id"
                            option-text="title"
                            placeholder="Select Receiver"
                          >
                          </model-list-select>
                          <small class="text-danger" v-if="errors.receiver_id">
                            {{ errors.receiver_id[0] }}
                          </small>
                        </div>
                      </div> -->
                    </div>
                    <div class="form-group">
                      <label>Note</label>
                      <textarea
                        class="form-control"
                        style="height: 100px"
                        v-model="form.note"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <!-- <div class="form-group">
                      <label>Account Code</label>
                      <model-list-select
                        v-model="form.account_code_id"
                        :list="account_codes"
                        option-value="id"
                        option-text="name"
                        placeholder="Select Account Code"
                      >
                      </model-list-select>
                      <small class="text-danger" v-if="errors.account_code_id">
                        {{ errors.account_code_id[0] }}
                      </small>
                    </div> -->
                    <div class="form-group">
                      <label>Payment Type</label>
                      <select
                        v-model="form.payment_type_id"
                        class="form-control"
                      >
                        <option value="">Select Payment Type</option>
                        <option
                          :value="payment_type.id"
                          v-for="(payment_type, index) in payment_types"
                          :key="index"
                        >
                          {{ payment_type.name }}
                        </option>
                      </select>
                      <small class="text-danger" v-if="errors.payment_type_id">
                        {{ errors.payment_type_id[0] }}
                      </small>
                    </div>
                    <div class="form-group">
                      <label>Description</label>
                      <textarea
                        class="form-control"
                        v-model="form.description"
                        style="height: 100px"
                      ></textarea>
                      <small class="text-danger" v-if="errors.description">
                        {{ errors.description[0] }}
                      </small>
                    </div>
                  </div>
                </div>

                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Stock</th>
                      <th>Unit</th>
                      <th>Price</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(stock, index) in form.stocks" :key="index">
                      <td>
                        <v2-select
                          v-model="stock.product_id"
                          :options="products"
                          :reduce="(products) => products.id"
                          @input="getProductUnitParams(index)"
                          label="name"
                        >
                        </v2-select>
                        <small class="text-danger" v-if="errors.product_id">{{
                          errors.product_id[0]
                        }}</small>
                      </td>
                      <td>
                        <input
                          type="number"
                          v-model="stock.stock"
                          placeholder="Enter Stock"
                          @change="getProductPrice(index)"
                          class="form-control"
                        />
                      </td>
                      <td>
                        <select
                          class="form-control"
                          @change="getProductPrice(index)"
                          v-model="stock.unit_id"
                        >
                          <option
                            v-for="unit in form.stocks[index].units"
                            :key="unit.id"
                            :value="unit.unit_id"
                          >
                            {{ unit.product_name }}/{{ unit.unit_name }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="stock.price"
                        />
                      </td>
                      <td>
                        <div v-if="index > 0">
                          <button
                            @click="deleteFind(index)"
                            class="btn btn-danger w-100"
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-7">
                    <button @click="addFind()" class="btn btn-success">
                      New Product
                    </button>
                  </div>
                  <div class="col">
                    <div class="invoice-detail-item">
                      <div class="invoice-detail-name">Total</div>
                      <h3 class="font-weight-bold invoice-detail-value-lg">
                        Rp. {{ total.toLocaleString() }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
import { apiGet } from '../../services/api';
import { PaymentTypeRepository } from '../../repositories/RepositoryFactory';

export default {
  name: 'FormProductStockSupplies',
  data() {
    return {
      name: 'Form Product Stock Supplies',
      form: {
        supplier_id: '',
        receiver_id: '',
        note: '',
        account_code_id: '',
        payment_type_id: '',
        description: '',
        amount: '',
        stocks: [
          {
            product_id: '',
            stock: '',
            unit_id: '',
            units: [],
            price: 0,
          },
        ],
      },
      suppliers: [],
      isSubmitting: false,
      errors: {},
      edit: false,
      venues: [],
      products: [],
      product_units_data: [],
      account_codes: [],
      payment_types: [],
    };
  },
  mounted() {
    this.getData();
    this.getVenue();
    this.getProduct();
    this.getSupplier();
    this.getDataAccount();
  },
  updated() {
    this.form.amount = this.total;
  },
  methods: {
    getVenue() {
      apiGet('venue')
        .then((result) => {
          this.venues = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSupplier() {
      apiGet('supplier')
        .then((result) => {
          this.suppliers = result.data.data;
        })
        .catch((err) => console.log(err));
    },
    getProductPrice(index) {
      apiGet('product_price/get_price', {
        product_id: this.form.stocks[index].product_id,
        unit_id: this.form.stocks[index].unit_id,
        stock: this.form.stocks[index].stock,
      }).then((res) => {
        this.form.stocks[index].price = res.data.data.price;
        this.form.amount = this.total;
      });
    },
    getData() {
      let id = this.$route.params.id;
      apiGet('stock_supplies/' + id)
        .then((result) => {
          this.form = result.data.data;
          this.edit = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProduct() {
      apiGet('product/all/minimal')
        .then((result) => {
          this.products = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProductUnitParams(index) {
      axios
        .get(this.$store.state.api + 'product_unit', {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            product_id: this.form.stocks[index].product_id,
          },
        })
        .then((result) => {
          this.form.stocks[index].units = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.getProductPrice(index);
        });
    },
    addFind: function () {
      this.form.stocks.push({
        product_id: '',
        stock: '',
        unit_id: '',
        units: [],
        price: 0,
      });
    },
    deleteFind: function (index) {
      this.form.stocks.splice(index, 1);
    },
    productStockSupplierInsert() {
      if (this.edit === false) {
        this.isSubmitting = true;
        axios
          .post(this.$store.state.api + 'stock_supplies/store', this.form, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success('Your Product Stock Supplies has been created!');
            this.$router.push({ name: 'ProductStockSupplies' });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = true;
        let id = this.$route.params.id;
        axios
          .post(
            this.$store.state.api + 'stock_supplies/update/' + id,
            this.form,
            {
              headers: {
                Authorization: this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.$noty.success('Your paymentType has been updated!');
            this.$router.push({ name: 'ProductStockSupplies' });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      }
    },
    getDataAccount() {
      apiGet('stock_supplies/' + this.id).then((result) => {
        let cashflow = result.data.data.cashflow;
        this.form.account_code_id = cashflow.account_code_child_id;
        this.form.amount = cashflow.amount;
        this.form.description = cashflow.description;
        this.form.payment_type_id = cashflow.payment_type_id;
      });
      PaymentTypeRepository.get()
        .then((result) => {
          this.payment_types = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      apiGet('stock_request/get_status').then((result) => {
        this.statuses = result.data;
      });
      apiGet('account_code/not-paginate').then((result) => {
        this.account_codes = result.data.data;
      });
    },
  },
  computed: {
    total() {
      let sum = 0;
      for (let index = 0; index < this.form.stocks.length; index++) {
        sum += parseInt(this.form.stocks[index].price);
      }
      return sum;
    },
  },
};
</script>
